import { Icon } from '@chakra-ui/react';

export const QuestionMarkIcon = (props) => (
  <Icon viewBox="0 0 100 100" {...props}>
    <path
      d="M49.8047 99.6094C56.6081 99.6094 63.0127 98.3073 69.0186 95.7031C75.0244 93.099 80.3223 89.502 84.9121 84.9121C89.5019 80.3223 93.0988 75.0244 95.7028 69.0186C98.3074 63.0127 99.6098 56.6081 99.6098 49.8047C99.6098 43.0013 98.3074 36.5967 95.7028 30.5908C93.0988 24.5849 89.5019 19.2871 84.9121 14.6973C80.3223 10.1074 75.0163 6.51044 68.9942 3.9063C62.972 1.3021 56.5593 0 49.7559 0C42.9525 0 36.5478 1.3021 30.542 3.9063C24.5361 6.51044 19.2464 10.1074 14.6729 14.6973C10.0993 19.2871 6.5104 24.5849 3.90627 30.5908C1.30209 36.5967 0 43.0013 0 49.8047C0 56.6081 1.30209 63.0127 3.90627 69.0186C6.5104 75.0244 10.1074 80.3223 14.6973 84.9121C19.2871 89.502 24.585 93.099 30.5909 95.7031C36.5967 98.3073 43.0013 99.6094 49.8047 99.6094ZM49.8047 91.3086C44.0429 91.3086 38.6556 90.2344 33.6426 88.0859C28.6296 85.9375 24.2269 82.9671 20.4346 79.1748C16.6422 75.3825 13.68 70.9798 11.5479 65.9668C9.41567 60.9538 8.34957 55.5664 8.34957 49.8047C8.34957 44.043 9.41567 38.6556 11.5479 33.6426C13.68 28.6296 16.6341 24.2188 20.4102 20.4102C24.1862 16.6016 28.5808 13.6312 33.5938 11.499C38.6068 9.36687 43.9941 8.3008 49.7559 8.3008C55.5176 8.3008 60.905 9.36687 65.918 11.499C70.931 13.6312 75.3418 16.6016 79.1504 20.4102C82.959 24.2188 85.9375 28.6296 88.086 33.6426C90.2345 38.6556 91.3088 44.043 91.3088 49.8047C91.3088 55.5664 90.2426 60.9538 88.1104 65.9668C85.9782 70.9798 83.0159 75.3825 79.2237 79.1748C75.4313 82.9671 71.0205 85.9375 65.9912 88.0859C60.9619 90.2344 55.5664 91.3086 49.8047 91.3086Z"
      fill="currentColor"
    />
    <path
      d="M48.8282 59.9121C50.0326 59.9121 50.9847 59.5703 51.6846 58.8867C52.3844 58.2031 52.7344 57.3893 52.7344 56.4453C52.7344 56.3476 52.7344 56.2418 52.7344 56.1279C52.7344 56.014 52.7344 55.9245 52.7344 55.8594C52.7344 54.4922 53.1413 53.3122 53.9551 52.3193C54.7689 51.3265 56.0384 50.2604 57.7637 49.1211C60.1074 47.5586 62.0443 45.931 63.5743 44.2383C65.1042 42.5456 65.8692 40.2344 65.8692 37.3047C65.8692 34.6029 65.1449 32.3243 63.6963 30.4688C62.2477 28.6133 60.3516 27.2054 58.0078 26.2451C55.664 25.2848 53.1087 24.8047 50.3418 24.8047C46.1426 24.8047 42.7165 25.6592 40.0635 27.3682C37.4105 29.0771 35.7422 31.0547 35.0586 33.3008C34.9284 33.6914 34.8307 34.082 34.7657 34.4727C34.7005 34.8633 34.668 35.2702 34.668 35.6934C34.668 36.8001 35.026 37.6383 35.7422 38.208C36.4583 38.7777 37.2233 39.0625 38.0371 39.0625C38.8509 39.0625 39.5426 38.8835 40.1123 38.5254C40.6819 38.1673 41.1784 37.6953 41.6016 37.1094L42.4805 35.9375C43.0664 34.9935 43.7337 34.1878 44.4825 33.5205C45.2311 32.8532 46.0693 32.3405 46.9971 31.9824C47.9248 31.6243 48.9258 31.4453 50 31.4453C52.2461 31.4453 54.0365 32.0231 55.3711 33.1787C56.7057 34.3343 57.3731 35.8398 57.3731 37.6953C57.3731 39.3555 56.8522 40.7146 55.8106 41.7725C54.7689 42.8304 53.1738 44.1081 51.0254 45.6055C49.2676 46.8099 47.8109 48.1445 46.6553 49.6094C45.4997 51.0742 44.9219 53.011 44.9219 55.4199C44.9219 55.5176 44.9219 55.6234 44.9219 55.7373C44.9219 55.8512 44.9219 55.957 44.9219 56.0547C44.9219 58.6263 46.224 59.9121 48.8282 59.9121ZM48.6817 74.4629C50.1139 74.4629 51.3509 73.9746 52.3926 72.9981C53.4342 72.0215 53.9551 70.8171 53.9551 69.3848C53.9551 67.9524 53.4342 66.748 52.3926 65.7715C51.3509 64.7949 50.1139 64.3066 48.6817 64.3066C47.2493 64.3066 46.0205 64.803 44.9952 65.7959C43.9698 66.7888 43.4571 67.9851 43.4571 69.3848C43.4571 70.7845 43.9779 71.9808 45.0196 72.9736C46.0612 73.9665 47.2819 74.4629 48.6817 74.4629Z"
      fill="currentColor"
    />
  </Icon>
);
