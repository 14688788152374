import { Box } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/react';
import { useStyleConfig } from '@chakra-ui/system';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const Content = ({ children }: Props) => {
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  return <Box sx={useStyleConfig('Content', { variant: isMobile && 'mobile' })}>{children}</Box>;
};
