import { Icon } from '@chakra-ui/react';

export const SignOutIcon = (props) => (
  <Icon viewBox="0 0 114 105" {...props}>
    <path
      d="M15.1367 104.688H66.9922C72.0377 104.688 75.8219 103.418 78.3447 100.879C80.8675 98.3399 82.1289 94.515 82.1289 89.4043V66.7481H74.2676V89.2579C74.2676 91.6993 73.6247 93.571 72.3388 94.8731C71.053 96.1752 69.1406 96.8262 66.6015 96.8262H15.5273C12.9882 96.8262 11.0758 96.1752 9.79 94.8731C8.5042 93.571 7.8613 91.6993 7.8613 89.2579V15.4786C7.8613 13.0372 8.5042 11.1573 9.79 9.83891C11.0758 8.52057 12.9882 7.8614 15.5273 7.8614H66.6015C69.1406 7.8614 71.053 8.52057 72.3388 9.83891C73.6247 11.1573 74.2676 13.0372 74.2676 15.4786V37.9395H82.1289V15.3321C82.1289 10.254 80.8675 6.42911 78.3447 3.85751C75.8219 1.28584 72.0377 0 66.9922 0H15.1367C10.0911 0 6.30693 1.28584 3.7842 3.85751C1.2614 6.42911 0 10.254 0 15.3321V89.4043C0 94.515 1.2614 98.3399 3.7842 100.879C6.30693 103.418 10.0911 104.688 15.1367 104.688Z"
      fill="currentColor"
    />
    <path
      d="M45.166 56.2012H95.3129L102.637 55.9082L99.1699 59.2286L91.3089 66.6016C90.5275 67.2852 90.1368 68.1641 90.1368 69.2383C90.1368 70.2474 90.4542 71.0938 91.0889 71.7774C91.7235 72.461 92.5455 72.8028 93.5548 72.8028C94.4988 72.8028 95.3615 72.4284 96.1429 71.6797L112.061 55.1758C112.549 54.6875 112.883 54.2155 113.062 53.7598C113.241 53.3041 113.33 52.8321 113.33 52.3438C113.33 51.8555 113.241 51.3835 113.062 50.9278C112.883 50.4721 112.549 50.0001 112.061 49.5118L96.1429 33.0079C95.3615 32.2266 94.4988 31.836 93.5548 31.836C92.5455 31.836 91.7235 32.1697 91.0889 32.837C90.4542 33.5043 90.1368 34.3425 90.1368 35.3516C90.1368 36.4584 90.5275 37.3536 91.3089 38.0372L99.1699 45.459L102.637 48.7793L95.3129 48.4375H45.166C44.1569 48.4375 43.278 48.8281 42.5294 49.6094C41.7806 50.3907 41.4062 51.3021 41.4062 52.3438C41.4062 53.3855 41.7806 54.2888 42.5294 55.0538C43.278 55.8187 44.1569 56.2012 45.166 56.2012Z"
      fill="currentColor"
    />
  </Icon>
);
