import { Flex, Spacer, Box, Text } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';
import Link from 'next/link';
import { Header } from './Header';
import { Logout } from './Logout';

import { SkipLink } from './SkipLink';
import { useMediaQuery } from '@chakra-ui/react';

export function PrimaryNav() {
  const [isMobile] = useMediaQuery('(max-width: 800px)');
  const styles = useMultiStyleConfig('PrimaryNav', { variant: isMobile && 'mobile' });
  return (
    <Header>
      <SkipLink />
      <Flex>
        <Box
          marginLeft="24px"
          paddingTop="var(--chakra-sizes-1)"
          paddingBottom="var(--chakra-sizes-1)"
        >
          <Text sx={styles.Title}>
            <Link href="/">
              <img
                loading="eager"
                src="/aa-logo-text.svg"
                alt="Again Again Logo"
                style={{ height: 'var(--chakra-sizes-8)' }}
              />
            </Link>
          </Text>
        </Box>
        <Spacer />
        <Box marginRight="24px">
          <Logout />
        </Box>
      </Flex>
    </Header>
  );
}
