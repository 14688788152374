import { Icon } from '@chakra-ui/react';

export const InventoryIcon = (props) => (
  <Icon viewBox="0 0 99 107" {...props}>
    <path
      d="M6.8359 83.5449L45.2636 105.322C46.6308 106.104 47.9736 106.494 49.292 106.494C50.6103 106.494 51.9694 106.104 53.3691 105.322L91.7478 83.5449C93.9938 82.2429 95.6948 80.7699 96.8508 79.126C98.0061 77.4821 98.5838 74.9187 98.5838 71.4356V32.7637C98.5838 30.2246 98.1038 28.125 97.1438 26.4649C96.1831 24.8047 94.6775 23.3724 92.6268 22.168L58.0566 2.53909C55.1269 0.84636 52.2135 0 49.3164 0C46.4193 0 43.4896 0.84636 40.5273 2.53909L6.0058 22.168C3.92247 23.3724 2.40067 24.8047 1.4404 26.4649C0.480133 28.125 0 30.2246 0 32.7637V71.4356C0 74.9187 0.585933 77.4821 1.7578 79.126C2.92967 80.7699 4.62237 82.2429 6.8359 83.5449ZM11.2304 77.0996C9.79813 76.2858 8.8053 75.4313 8.2519 74.5362C7.6985 73.641 7.4218 72.5261 7.4218 71.1914V34.3262L45.459 56.0547V96.582L11.2304 77.0996ZM87.4023 77.0996L53.125 96.582V56.0547L91.1618 34.3262V71.1914C91.1618 72.5261 90.8851 73.641 90.3318 74.5362C89.7785 75.4313 88.802 76.2858 87.4023 77.0996ZM49.3164 49.2188L11.6211 27.8809L26.709 19.2383L64.4043 40.6738L49.3164 49.2188ZM72.2656 36.2305L34.4238 14.8438L43.7988 9.5215C47.5097 7.40563 51.1881 7.40563 54.834 9.5215L87.0117 27.8809L72.2656 36.2305Z"
      fill="currentColor"
    />
  </Icon>
);
