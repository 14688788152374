import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import { Button } from '@chakra-ui/button';
import { CheveronDownIcon } from './Icons/CheveronDownIcon';
import { SignOutIcon } from './Icons/SignOutIcon';
import { useAuth0 } from '@auth0/auth0-react';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { useUserContext } from './UserContext';

export const Logout = () => {
  const styles = useMultiStyleConfig('Logout', {});
  const { logout, isAuthenticated } = useAuth0();

  const { user } = useUserContext();
  return isAuthenticated ? (
    <Menu>
      <MenuButton as={Button} sx={styles.Button} rightIcon={<CheveronDownIcon />}>
        {user?.profile.fullName || 'Logout'}
      </MenuButton>
      <MenuList>
        <MenuItem
          sx={styles.MenuItem}
          onClick={() => logout({ returnTo: process.env.NEXT_PUBLIC_AUTH_BASE_URL })}
        >
          <Box as="span" marginRight="8px">
            <SignOutIcon />
          </Box>
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <></>
  );
};
