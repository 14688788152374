import { Icon } from '@chakra-ui/react';

export const CheveronDownIcon = (props) => (
  <Icon viewBox="0 0 85 48" {...props}>
    <path
      d="M42.4316 47.7539C43.0501 47.7539 43.6441 47.6318 44.2138 47.3877C44.7835 47.1435 45.2799 46.7936 45.7031 46.3379L83.4961 7.6172C84.375 6.73827 84.8144 5.6966 84.8144 4.4922C84.8144 3.6458 84.6191 2.8808 84.2285 2.1972C83.8379 1.51367 83.3089 0.976566 82.6416 0.585899C81.9743 0.195299 81.2174 0 80.3711 0C79.1666 0 78.1087 0.423167 77.1972 1.2695L39.7949 39.5019H45.0195L7.6172 1.2695C6.73827 0.423167 5.6803 0 4.4433 0C3.59697 0 2.84013 0.195299 2.1728 0.585899C1.50547 0.976566 0.9765 1.51367 0.5859 2.1972C0.1953 2.8808 0 3.6458 0 4.4922C0 5.11067 0.113934 5.68846 0.341801 6.2256C0.569667 6.76266 0.895167 7.2428 1.3183 7.666L39.1113 46.3379C40.0878 47.2819 41.1946 47.7539 42.4316 47.7539Z"
      fill="currentColor"
    />
  </Icon>
);
