import Link from 'next/link';
import { useRouter } from 'next/router';
import { Link as ChakraLink, useMultiStyleConfig } from '@chakra-ui/react';

function SideBarNavLink({ children, href }) {
  const router = useRouter();
  const variant = router.pathname.startsWith(href) ? 'dark' : 'light';
  const styles = useMultiStyleConfig('SideBarNav', { variant });

  return (
    <Link href={href} passHref>
      <ChakraLink sx={styles.NavLink}>{children}</ChakraLink>
    </Link>
  );
}

export default SideBarNavLink;
