import { Box } from "@chakra-ui/layout";
import { useStyleConfig } from "@chakra-ui/system";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const Header = ({ children }: Props) => (
  <Box as="header" sx={useStyleConfig("Header")} role="banner">
    {children}
  </Box>
);
