import { Icon } from '@chakra-ui/react';

export const PromotionsIcon = (props) => (
  <Icon viewBox="0 0 110 110" {...props}>
    <path
      d="M57.5687 103.809L105.176 56.1035C106.413 54.8666 107.3 53.7517 107.837 52.7588C108.374 51.7659 108.716 50.6917 108.863 49.5361C109.009 48.3805 109.082 46.9401 109.082 45.2149V26.6602C109.082 24.9349 108.928 23.527 108.619 22.4365C108.31 21.346 107.797 20.3369 107.081 19.4092C106.364 18.4815 105.388 17.3991 104.151 16.1621L92.9199 4.9317C91.6833 3.72723 90.6093 2.75881 89.6979 2.02641C88.7863 1.29394 87.7852 0.773105 86.6947 0.463905C85.6043 0.154639 84.1964 0 82.4711 0H63.8676C62.1749 0 60.7426 0.073237 59.5707 0.219704C58.3989 0.366237 57.3165 0.716167 56.3236 1.2695C55.3308 1.8229 54.2322 2.70184 53.0277 3.9063L5.27384 51.5625C1.79077 55.0456 0.0329745 58.5775 0.000441183 62.1582C-0.0321588 65.7389 1.74191 69.3197 5.32264 72.9004L36.2308 103.76C39.8116 107.308 43.3923 109.074 46.973 109.058C50.5538 109.041 54.0857 107.292 57.5687 103.809ZM52.2953 97.9004C48.7146 101.514 45.1176 101.497 41.5043 97.8516L11.182 67.5781C9.39171 65.7878 8.50467 63.973 8.52094 62.1338C8.53721 60.2946 9.40798 58.5124 11.1332 56.7871L58.5453 9.4727C59.0336 8.98437 59.5626 8.59374 60.1322 8.3008C60.7019 8.0078 61.3936 7.86131 62.2074 7.86131H82.764C84.0987 7.86131 85.2869 8.39844 86.3285 9.4727L99.6589 22.7539C100.7 23.7956 101.221 24.9837 101.221 26.3184V46.9238C101.221 47.7376 101.083 48.4212 100.806 48.9746C100.529 49.528 100.147 50.0488 99.6589 50.5371L52.2953 97.9004ZM76.7582 39.0625C78.6788 39.0625 80.2738 38.4115 81.5433 37.1094C82.8129 35.8073 83.4476 34.2286 83.4476 32.3731C83.4476 30.485 82.8129 28.8981 81.5433 27.6123C80.2738 26.3265 78.6788 25.6836 76.7582 25.6836C74.8376 25.6836 73.2426 26.3265 71.973 27.6123C70.7035 28.8981 70.0687 30.485 70.0687 32.3731C70.0687 34.2286 70.7035 35.8073 71.973 37.1094C73.2426 38.4115 74.8376 39.0625 76.7582 39.0625Z"
      fill="currentColor"
    />
  </Icon>
);
