import { Text, Link as ChakraLink } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { brandColours } from 'theme/aa/aa-theme';
import { useUserContext } from './UserContext';

const ErrorAccount = () => {
  const { user } = useUserContext();
  return (
    <Box>
      <Text>You do not have any locations associated with your account.</Text>
      <Text mt={2}>
        Please contact our support team at{' '}
        <ChakraLink
          color={brandColours.blue}
          href={`mailto:support@againagain.co?subject=No locations associated ${user?.email}`}
        >
          support@againagain.co
        </ChakraLink>
        .
      </Text>
    </Box>
  );
};

export default ErrorAccount;
