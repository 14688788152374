import { Icon } from '@chakra-ui/react';

export const CardIcon = (props) => (
  <Icon viewBox="0 0 28 28" {...props}>
    <g clipPath="url(#clip0_6168_19160)">
      <path fillRule="evenodd" clipRule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM8.17568 20H7V8H8.17568V20ZM9.98609 20H9.3153V8H9.98609V20ZM13.0299 20H11.1113V8H13.0299V20ZM14.9557 20H13.7295V8H14.9557V20ZM17.5883 20H15.6842V8H17.5883V20ZM19.6656 20H18.1726V8H19.6656V20ZM21 20H20.0335V8H21V20Z" fill="#0072CE"/>
    </g>
    <defs>
      <clipPath id="clip0_6168_19160">
        <rect width="28" height="28" fill="white"/>
      </clipPath>
    </defs>
  </Icon>
);
