import { Box, Flex } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { CSSObject } from '@emotion/react';

type Props = {
  children: any;
  sx?: any;
};

export const Main = ({ children, sx }: Props) => {
  

  return (
    <Box as="main" sx={sx} role="main">
      <Flex wrap="wrap">{children}</Flex>
    </Box>
  );
};
