import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { User } from '__generated__/models';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useUserContext } from './UserContext';

const ProfileCreate = () => {
  const { user: authUser } = useAuth0();
  const { user, updateUser, isLoading, error } = useUserContext();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    user?.profile?.onboardingCompleted === false ? onOpen() : onClose();
  }, [user]);

  const saveAndClose = async (event) => {
    event.preventDefault();

    if (user !== undefined) {
      //Only interrupt if we actually have a user, else just carry on and we pick it up some other time
      const updatedUser = {
        ...user,
        firstName: event.target.firstName.value as string,
        lastName: event.target.lastName.value as string,
        email: event.target.email.value as string,
        profile: {
          ...user?.profile,
          fullName: `${event.target.firstName.value} ${event.target.lastName.value}`,
          preferredName: event.target.firstName.value,
          onboardingCompleted: true,
        },
      };

      if (updateUser !== undefined) {
        let res = await updateUser(updatedUser);
        if (res !== undefined) {
          onClose();
        }
      }
    } else {
      onClose();
    }
  };

  const errorMessage = (
    <Box mt={4}>
      <Alert status="error">
        <AlertIcon />
        {error?.message}
      </Alert>
    </Box>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Complete account setup</ModalHeader>
        <form onSubmit={saveAndClose}>
          <ModalBody pb={6}>
            <Text>We need to confirm your details, then you'll be on your way!</Text>
            <FormControl mt={4} isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                id="firstName"
                placeholder="First name"
                defaultValue={user?.firstName ? user.firstName : authUser?.given_name}
              />
            </FormControl>
            <FormControl mt={4}>
              {/* Not required in case user only uses one name */}
              <FormLabel>Last name</FormLabel>
              <Input
                id="lastName"
                placeholder="Last name"
                defaultValue={user?.lastName ? user.lastName : authUser?.family_name}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                id="email"
                placeholder="Email"
                defaultValue={user?.email ? user.email : authUser?.email}
              />
            </FormControl>
            {!!error && errorMessage}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={isLoading}
              isDisabled={!!error}
            >
              Continue
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ProfileCreate;
